import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { IProduct, IProductTabs } from 'types/DTO/products'
import { IMetaData } from 'types/DTO/common/response'
import { IHttpParams } from 'services/common/Http'

export const state = () => ({
  popularProducts: [] as IProduct[],
  nearestProducts: [] as IProduct[],
  products: [] as IProduct[],
  product: {} as IProduct,
  annotations: [] as IProductTabs[],
  meta: {} as IMetaData,
  analogs: [] as IProduct[],
  foundProducts: [] as IProduct[],
  searchError: false as boolean,
})

export type stateT = ReturnType<typeof state>

export const namespaced = true

export const mutations: MutationTree<stateT> = {
  setPopularProducts(state, payload) {
    state.popularProducts = payload
  },
  setNearestProducts(state, payload) {
    state.nearestProducts = payload
  },
  setProducts(state, payload) {
    state.products = payload
  },
  setProduct(state, payload) {
    state.product = payload
  },
  setAnalogs(state, payload) {
    state.analogs = payload
  },
  setMeta(state, payload) {
    state.meta = payload
  },
  setAnnotations(state, payload) {
    state.annotations = payload
  },
  setFoundProducts(state, payload) {
    state.foundProducts = payload
  },
  clearFoundProducts(state) {
    state.foundProducts = []
  },
  setSearchError(state, payload) {
    state.searchError = payload
  },
}

export const actions: ActionTree<stateT, stateT> = {
  async getPopularProducts({ commit, state }, params: IHttpParams) {
    try {
      if (state.popularProducts.length) return
      const res = await this.$repositories.products.findAll(params)
      commit('setPopularProducts', res.data?.data ?? [])
    } catch {
      console.log('error: getProducts')
    }
  },

  async getNearestProducts({ commit, state }, params: IHttpParams) {
    try {
      if (state.nearestProducts.length) return
      const res = await this.$repositories.products.findAll(params)
      commit('setNearestProducts', res.data.data)
    } catch {
      console.log('error: getProducts')
    }
  },

  async getProducts({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.products.findAll(params)
      commit('setProducts', res.data?.data ?? [])
      // @ts-ignore
      commit('setMeta', res.data?.meta)
    } catch {
      console.log('error: getProducts')
    }
  },

  async getProductAnalogs({ commit }, { id, params }) {
    try {
      const res = await this.$repositories.products.findAnalogs(id, params)
      commit('setAnalogs', res.data)
      // @ts-ignore
      commit('setMeta', res.meta)
    } catch {
      console.log('error: getProductAnalogs')
    }
  },

  async getProductBySlug({ commit }, { slug, category, params }) {
    try {
      const res = await this.$repositories.products.findBySlug(slug, category, params)
      const annotations = this.$services.product.mapDropDownSpecs(res.data.annotation)
      commit('setProduct', res.data)
      commit('setAnnotations', annotations)
      commit('setAnalogs', res.data.similar)
    } catch (error) {
      console.log('error: getProductBySlug', error)
    }
  },

  async getProductsByQuery({ commit }, params: IHttpParams) {
    try {
      const res = await this.$repositories.products.findAll(params)

      commit('setFoundProducts', res.data?.data ?? [])
      commit('setSearchError', false)
    } catch {
      commit('setSearchError', true)
      console.log('error: getProductsByQuery')
    }
  },
}

export const getters: GetterTree<stateT, stateT> = {
  popularProducts(state) {
    return state.popularProducts
  },
  nearestProducts(state) {
    return state.nearestProducts
  },
  products(state) {
    return state.products
  },
  analogs(state) {
    return state.analogs
  },
  product(state) {
    return state.product
  },
  meta(state) {
    return state.meta
  },
  annotations(state) {
    return state.annotations
  },
  foundProducts(state) {
    return state.foundProducts
  },
  analogsCount(state) {
    return state.analogs.length
  },

  searchError(state) {
    return state.searchError
  },
}
